<!-- Page Dashboard for Partner  -->
<template>
  <auth-content>
    <el-header slot="header" :title="$t(module + '.partenariat.title')" :titleSuffix="$t('modules.partnership.titles.partnership_index')" :favorites="false" />
    <div class="card card-content live__scroll">
      <div class="card-body">
        <div class="row row-menu ">
          <div class="col-4 border-right">
            <div class="">
              <el-card-title :title="$t(module + '.partenariat.dash.dash_title')" />
              <p class="small mt-2 font-weight-semibold">{{ $t(module + '.partenariat.dash.dash_desc') }}</p>
            </div>
          </div>
          <div class="col-4 border-right">
          </div>
          <div class="col-1 border-right text-center">
            <span :class="{'level-wrapper': true, 'isActive': worldNiveau == 1}">
              <i class="silver icon-medal-third mb-2" />
              <span class="font-weight-bold small">{{ $t(module + '.partenariat.dash.SILVER') }}</span>
            </span>
          </div>
          <div class="col-1 border-right text-center">
            <span :class="{'level-wrapper': true, 'isActive': worldNiveau == 2}">
              <i class="gold icon-medal-second mb-2" />
              <span class="font-weight-bold small">{{ $t(module + '.partenariat.dash.OR') }}</span>
            </span>
          </div>
          <div class="col-1 border-right text-center">
            <span :class="{'level-wrapper': true, 'isActive': worldNiveau == 3}">
              <i class="platine icon-medal-first mb-2" />
              <span class="font-weight-bold small">{{ $t(module + '.partenariat.dash.PLATINE') }}</span>
            </span>
          </div>
          <div class="col-1 border-right text-center">
            <span :class="{'level-wrapper': true, 'isActive': worldNiveau == 4}">
              <i class="diamant icon-trophy4 mb-2" />
              <span class="font-weight-bold small">{{ $t(module + '.partenariat.dash.DIAMANT') }}</span>
            </span>
          </div>
        </div>
        <div class="row row-getion">
          <div class="col-4 border-right">
            <p class="font-weight-semibold">{{ $t(module + '.partenariat.dash.contenu_2') }}</p>
          </div>
          <div class="col-4 border-right">
            {{ $tc(module + '.partenariat.dash.based_on_clients', mrrGestionCount, {count: mrrGestionCount }) }}
          </div>
          <div class="col-1 border-right" v-for="item in mrrProgress[0].MRR_VENTE">
            {{ currency(item, false, false) }}
          </div>
          <div class="row-progress">
            <div class="col price"> {{ currency(MRR_VENTE.toLocaleString(), false, false) }} </div>
            <div class="col text">
              <span class="font-weight-semibold small">{{ $t(module + '.partenariat.dash.mrr_sales') }}</span>
            </div>
            <div class="progress">
              <div class="progress-bar bg-success" role="progressbar" :style="'width:'+progressbar(mrrProgress[0].MRR_VENTE, MRR_VENTE )" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row row-getion">
          <div class="col-4 border-right">
            <p class="font-weight-semibold">{{ $t(module + '.partenariat.dash.contenu_3') }}
              <span class="certificate" v-if="worldCertification != null"> : <b>{{ certification[worldCertification] }}</b> </span>
              <span class="certificate diamant icon-cross2 text-danger" v-else="worldCertification != null"></span>
            </p>
          </div>
          <div class="col-4 border-right">
            {{ $tc(module + '.partenariat.dash.based_on_clients', mrrVenteCount, {count: mrrVenteCount}) }}
          </div>
          <div class="col-1 border-right" v-for="item in mrrProgress[1].MRR_GESTION">
            {{ currency(item, false, false) }}
          </div>
          <div class="row-progress">
            <div class="col price">{{ currency(MRR_GESTION.toLocaleString(), false, false) }} </div>
            <div class="col text">
              <span class="font-weight-semibold small">{{ $t(module + '.partenariat.dash.mrr_gestion') }}</span>
            </div>
            <div class="progress">
              <div class="progress-bar bg-success" role="progressbar" :style="'width:'+progressbar(mrrProgress[1].MRR_GESTION, MRR_GESTION )" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-table
      v-if="clients.length > 0"
      :title="$t('modules.partnership.partenariat.dash.table.title')"
      :total="Object.keys(clients).length"
      :columns="columns"
      :data="clients"
    />

    <prospect-management />

  </auth-content>
</template>

<script>
import store from '@/store'
import ProspectManagement from '@/../Modules/Partnership/components/ProspectManagement'
import {
  WORLD_REQUEST
} from '@/store/mutations-types'
import {
  PARTNERSHIP_MRR_REQUEST,
  CURRENCIE_DATA
} from '@/../Modules/Partnership/mutations-types'
import moment from 'moment'

export default {
  name: 'PartnershipDashboard',
  components: {
    'prospect-management': ProspectManagement,
  },
  data () {
    return {
      module: 'modules.partnership',
      mrrProgress: [
        {MRR_VENTE: []},
        {MRR_GESTION: []},
      ],
      MRR_VENTE: 0,
      MRR_GESTION: 0,
      worldNiveau: store.getters['auth/getWorld'].niveau,
      worldCertification: store.getters['auth/getWorld'].certification,
      certification: {},
      global: {},
      mrrGestionCount: 0,
      mrrVenteCount: 0,
      columns: {
        company: this.$t('modules.partnership.partenariat.dash.table.company'),
        date_activation_partnership: this.$t('modules.partnership.partenariat.dash.table.date_invitation'),
        mode_payant: this.$t('modules.partnership.partenariat.dash.table.mode_payant'),
        mrr: this.$t('modules.partnership.partenariat.dash.table.mrr'),
        forfait: this.$t('modules.partnership.partenariat.dash.table.forfait'),
      },
      clients: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('partnership/' + PARTNERSHIP_MRR_REQUEST, store.getters['auth/getWorld'].id).then(data => {
      store.dispatch('partnership/' + CURRENCIE_DATA, store.getters['auth/getWorld'].id).then(global => {
        next(vm => {
          vm.global = {...global}
          vm.mrrProgress = data.mrr
          vm.MRR_VENTE = store.getters['auth/getWorld'].mrr_vente
          vm.MRR_GESTION = store.getters['auth/getWorld'].mrr_gestion
          vm.certification = data.certification
          vm.mrrGestionCount = data.worldStripeData.count_clients
          vm.mrrVenteCount = data.worldStripeData.count_clients_month
          data.clients.map(item => {
            item.mrr = vm.currency(item.mrr, false, false)
            if(item.mode_payant == "paid") {
              item.mode_payant = vm.$t(vm.module + '.partenariat.dash.table.Vendu')
            } else {
              item.mode_payant = vm.$t(vm.module+'.partenariat.dash.table.Non_vendu')
            }
            item.date_activation_partnership = moment(item.date_activation_partnership, 'YYYY-MM-DD').format('DD/MM/YYYY');
           })
           vm.clients = data.clients
        })
      })
    })
  },
  methods: {
    /**
    * @vuese
    *  this function controlle bar progress
    */
    progressbar (dataProgress = [], mrr) {
      mrr = parseFloat(mrr);
      var progress = 0, min_mrr = 0
      const prencetage = [50.33, 63, 75.9, 88.8, 100]
      var min_perct = 0
      for(var i = 0 ; i < dataProgress.length; i++) {
        if(mrr < dataProgress[i]) {
          if(i > 0) {
            min_perct =  prencetage[i-1]
            min_mrr = dataProgress[i-1]
          }

          var max_perct = prencetage[i]
          var max_mrr = dataProgress[i]
          var avg = 0
          if(mrr > min_mrr){
            avg = ((mrr-min_mrr)/(max_mrr - min_mrr))*100
          }

          progress = min_perct + (avg * (max_perct - min_perct) / 100)
          return `${progress}%`
        }
      }
      return `100%`
    }
  }
}
</script>

<style lang="scss">
.row-section {
  margin-bottom: 50px;
  border-bottom: 1px solid #b5b5b561;
  padding-bottom: 25px;
  .row-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 28px;
    &.collapse {
      display: none;
      &.show {
        display: flex;
      }
    }
  }
  .type-list{
    background: #fff0;
    border: none;
    font-size: 22px;
    font-weight: 500;
    i{
      color: #17bed0
    }
  }
}
.level-wrapper {
  opacity: 0.5;
  &.isActive  {
    opacity: 1;
  }
}
.row-menu{
  height: 170px;
  .item{
    text-align: center;
  }
  span i {
    font-size: 46px;
    display: block;
    &.silver {
      color:  #C0C0C0;
    }
    &.gold{
      color: #FFD700;
    }
    &.platine{
      color: #e5e4e2;
    }
    &.diamant {
      color: #c5ddf9;
    }
  }
}
.row-getion{
  position: relative;
  height: 120px;
  p{
    text-align: left;
  }
}
.row-progress {
  width: 65%;
  margin-left: 34%;
  position: absolute;
  top: -5pc;
  .price{
    font-size: 22px;
    font-weight: 500;
    padding-left: 0;
  }
  .text{
    padding-left: 0;
    .info{
      float: right;
    }
  }
}

.progress-bar {
  animation: progress 1.5s ease-in-out forwards;
}

@keyframes progress {
  from {
    width: 0;
  }
}

@media screen and (max-width: 992px) {
  .live__scroll {
    min-width: 900px;
    overflow-x: scroll;
  }
}
.certificate {
  float: right;
}

</style>
