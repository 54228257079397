<template>
  <auth-content>
    <el-header slot="header" :title="$t(module + '.partenariat.title')" :titleSuffix="$t('modules.partnership.titles.partnership_directory_profile')" :favorites="false">
      <div slot="actions">
        <el-header-action
          v-if="directoryUrl"
          @click.prevent.native="openDirectory()"
          icon="new-tab"
          :label="$t(module + '.partenariat.view_directory')"
        />
      </div>
    </el-header>
    <form @submit.prevent="saveProfile">
      <div class="card card-content">
        <div class="card-header">
          <el-card-title :title="$t(module + '.partenariat.form.title')" />
        </div>
        <div class="card-body">

<!--               <div class="file-upload-form">
            <label class="mb-1 font-weight-bold">
              {{ $t(module + '.partenariat.form.picture') }}
            </label>
            <input ref="pictureInput" class="d-none" type="file" @change="previewPicture" accept="image/gif, image/jpeg, image/png" />
            <div v-if="profile.logo">
              <el-avatar v-if="profile.logo.base64" :src="profile.logo.base64"></el-avatar>
              <el-avatar v-if="profile.logo.thumb" :src="profile.logo.thumb"></el-avatar>
              <div @click="changePicture()" class="ml-2 btn btn-sm btn-light">{{ $t(module + '.partenariat.form.picture_change') }}</div>
            </div>
            <div>
              <div @click="changePicture()" class="btn btn-sm btn-light" v-if="!profile.logo">{{ $t(module + '.partenariat.form.picture_add') }}</div>
            </div>
          </div>
-->
          <div class="" @click="$set(profile, 'public', !profile.public)">
            <i v-if="profile.public" class="icon-checkbox-checked2 mr-1 text-success" />
            <i v-else class="icon-checkbox-unchecked2 mr-1 text-grey" />
            <label class="mt-2 mb-1 font-weight-bold cursor-pointer">{{ $t(module + '.partenariat.form.public') }}</label>
          </div>

          <div v-if="profile.public">

            <label class="mt-2 mb-1 font-weight-bold">{{ $t(module + '.partenariat.form.website') }}</label>
            <input v-model="profile.website" class="form-control" type="url" :placeholder="$t(module + '.partenariat.form.website')"/>

            <label class="mt-2 mb-1 font-weight-bold">{{ $t(module + '.partenariat.form.location') }}</label>
            <input v-model="profile.location" class="form-control" type="text" :placeholder="$t(module + '.partenariat.form.location')"/>

            <label class="mt-2 mb-1 font-weight-bold">{{ $t(module + '.partenariat.form.description') }}</label>
            <textarea v-model="profile.description" class="form-control" :placeholder="$t(module + '.partenariat.form.description')"/>

            <label class="mt-2 mb-1 font-weight-bold">{{ $t(module + '.partenariat.form.iban') }}</label>
            <input v-model="profile.iban" minlength="3" maxlength="50" class="form-control" type="text" :placeholder="$t(module + '.partenariat.form.iban')"/>
            <div class="small text-grey mt-1">ex: FR76 3000 6000 0112 3456 7890 189</div>

            <div v-if="categories" class="mt-3">
              <el-card-title :title="$t(module + '.partenariat.form.categories')" />
              <div class="row">
                <div v-for="(category, categoryKey) in categories.categories" class="my-1 col-md-6">
                  <div class="">
                    <div class="font-weight-bold my-1">{{ category }}</div>
                    <div v-for="(subCat, subCatKey) in categories['categories_' + categoryKey]">
                      <label class="mb-0 pl-2 cursor-pointer">
                        <i v-if="profile.categories && profile.categories.includes(categoryKey + '_' + subCatKey)" class="icon-checkbox-checked2 mr-1 text-success" />
                        <i v-else class="icon-checkbox-unchecked2 mr-1 text-grey" />
                        <input class="d-none" type="checkbox" :id="categoryKey + '_' + subCatKey" :value="categoryKey + '_' + subCatKey" v-model="profile.categories">
                        {{ subCat }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>

        <div class="card-footer">
          <button v-if="!loadingProfile" class="btn btn-lg btn-success text-white">{{ $t('common.save') }}</button>
          <div v-else class="p-a">
            <span class="text-gray"><loader /></span>
          </div>
        </div>

      </div>
    </form>

  </auth-content>
</template>

<script>

import store from '@/store'
import {
  PARTNERSHIP_PROFILE,
  PARTNERSHIP_PROFILE_REQUEST
} from '../mutations-types'

export default {
  name: 'PartnershipDirectoryProfile',
  data () {
    return {
      module: 'modules.partnership',
      loadingProfile: false,
      profile: {},
      directoryUrl: null,
      categories: null,
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('partnership/' + PARTNERSHIP_PROFILE_REQUEST, to.params.world).then(resp => {
      next(vm => {
        vm.directoryUrl = resp.directoryUrl
        vm.profile = resp.profile
        vm.categories = resp.categories
        if (!vm.profile.public) {
          vm.profile.public = false
        }
      })
    })
  },
  methods: {
    /**
    * @vuese
    *  Store data profile
    */
    saveProfile () {
      this.loadingProfile = true
      this.$store.dispatch('partnership/' + PARTNERSHIP_PROFILE, {profile: this.profile, worldId: this.$route.params.world}).then(resp => {
        this.notifSuccess(this.$t(this.module + '.partenariat.form.saved'))
        this.loadingProfile = false
      }).catch(error => {
        this.notifError(error)
        this.loadingProfile = false
      })
    },
    openDirectory () {
      window.open(this.directoryUrl, '_blank').focus();
    },
    // async previewPicture (event) {
    //   // Reference to the DOM input element
    //   var input = event.target
    //   // Ensure that you have a file before attempting to read it
    //   if (input.files && input.files[0]) {
    //     // create a new FileReader to read this image and convert to base64 format
    //     var reader = new FileReader()
    //     // Define a callback function to run, when FileReader finishes its job
    //     reader.onload = (e) => {
    //       // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
    //       // Read image as base64 and set to imageData
    //       this.profile.logo = {}
    //       this.profile.logo.base64 = e.target.result
    //     }
    //     // Start the reader job - read file as a data url (base64 format)
    //     reader.readAsDataURL(input.files[0])
    //   }
    // },
    /**
    * @vuese
    * change picher profile
    */
    // changePicture () {
    //   this.profile.logo = null
    //   this.$refs.pictureInput.click()
    // },
  }
}
</script>

<style lang="scss" scoped>
</style>
